@media print
  body.admin, body.admin.has-sidebar
    header
      display: none
    nav
      display: none
    #content-wrap
      .wrapper
        width: 100%
        margin: 0px
        padding: 0px
        #main
          width: 100%
          margin: 0px
          padding: 0px
  article
    padding: 0px
    margin: 0px
